<template>
    <div class="questions-list-item">
        <slot>
            <h3>
                <slot name="question">{{ question }}</slot>
            </h3>
            <p v-html="answer"></p>
        </slot>
    </div>
</template>

<script>
export default {
    props: {
        question: String,
        answer: String,
    },
}
</script>

<style></style>
