module.exports = `<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><i><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>latest update: February 21, 2020</span></i></p>
<br>
<p class=MsoNormal align=left style='text-align:left;line-height:28.5pt;
background:white'><span lang=EN-US style='font-size:24.0pt;font-family:"Arial",sans-serif;
color:#454A50'>1. INTRODUCTION</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>In this Privacy Policy we describe our privacy practices in
relation to information that we collect through applications, websites and
other digital channels, operated by us. This includes our websites and social
media channels and other services provided by us online; as well as email
messages that we send to you (collectively called our&nbsp;<b>“Digital
Channels”</b>).</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>Digital Channels include our applications and websites</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>Soracom Mobile App</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='color:black'><a
href="https://soracommobile.com"><span style='font-size:14.5pt;font-family:
"Arial",sans-serif';>https://soracommobile.com</span></a></span><span
lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#454A50'>
,</span><span lang=EN-US style='color:black'><a href="https://soracom.io/"><span
style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#006BB7'>https://soracom.io&nbsp;</span></a></span><span
lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#454A50'>,</span><span
lang=EN-US style='color:black'><a href="https://dev.soracom.io/"><span
style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#006BB7'>https://dev.soracom.io&nbsp;</span></a></span><span
lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#454A50'>,</span><span
lang=EN-US style='color:black'><a href="https://soracom.jp/"><span
style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#006BB7'>https://soracom.io&nbsp;</span></a></span><span
lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#454A50'>,&nbsp;</span><span
lang=EN-US style='color:black'><a href="https://sps.soracom.io/"><span
style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#006BB7'>https://sps.soracom.io</span></a></span><span
lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#454A50'>,&nbsp;</span><span
lang=EN-US style='color:black'><a href="https://console.soracom.io/"><span
style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#006BB7'>https://console.soracom.io</span></a></span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>as well as social media channels like</span><span lang=EN-US
style='color:black'><a href="https://www.linkedin.com/company/soracom-inc./"><span
style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#006BB7'>https://www.linkedin.com/company/soracom-inc./o&nbsp;</span></a></span><span
lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#454A50'>and&nbsp;</span><span
lang=EN-US style='color:black'><a href="https://www.facebook.com/soracom.io"><span
style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#006BB7'>https://www.facebook.com/soracom.io</span></a></span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>When we refer to “we “, “us”, “our”, “Soracom” or use similar
phrasings we refer to Soracom CORPORATION, LTD., a company incorporated in the
UK with company registration number 12311887, who is the data controller for
personal information regarding customers. If you want to contact us for any
reason, please see&nbsp;</span><u><span lang=EN-US style='font-size:14.5pt;
font-family:"Arial",sans-serif;color:#006BB7'>How to contact us</span></u><span
lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#454A50'>&nbsp;at
the bottom of this policy.</span></p>
<br>
<p class=MsoNormal align=left style='text-align:left;line-height:28.5pt;
background:white'><span lang=EN-US style='font-size:24.0pt;font-family:"Arial",sans-serif;
color:#454A50'>2. THE DATA SORACOM COLLECTS AND PROCESSES</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><b><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>“Personal data”</span></b><span lang=EN-US style='font-size:
14.5pt;font-family:"Arial",sans-serif;color:#454A50'>&nbsp;is information that
identifies you as an individual, such as: name, title, company name, job role,
expertise, postal address, telephone number, IP-address or email address. We
also collect&nbsp;<b>other types</b>&nbsp;of information through cookies like
browser information, time of visit, referring site etc. More information about
this you find in our&nbsp;</span><span lang=EN-US style='color:black'><a
href="https://www.soracom.io/cookie_eu"><span style='font-size:14.5pt;
font-family:"Arial",sans-serif;color:#006BB7'>Cookie policy.</span></a></span></p>
<br>
<p class=MsoNormal align=left style='text-align:left;line-height:28.5pt;
background:white'><span lang=EN-US style='font-size:24.0pt;font-family:"Arial",sans-serif;
color:#454A50'>3. HOW WE COLLECT PERSONAL DATA</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>We collect personal information in a variety of ways, including:</span></p>

<p class=MsoNormal align=left style='margin-left:13.5pt;text-align:left;
text-indent:-18.0pt;background:white'><span lang=EN-US style='font-size:10.0pt;
font-family:Symbol;color:#6C7383'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#6C7383'>Through Digital Channels:</span></b><span lang=EN-US
style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#6C7383'>&nbsp;We
collect personal information through our Digital Channels, e.g., when you
subscribe to a newsletter, register for a webinar, complete our contact us
form, download content (e.g. whitepapers), sign up to use a service, or place
an order requiring a physical delivery address.</span></p>

<p class=MsoNormal align=left style='margin-left:13.5pt;text-align:left;
text-indent:-18.0pt;background:white'><span lang=EN-US style='font-size:10.0pt;
font-family:Symbol;color:#6C7383'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#6C7383'>Offline:</span></b><span lang=EN-US style='font-size:14.5pt;
font-family:"Arial",sans-serif;color:#6C7383'>&nbsp;We may collect personal
information from you offline, such as when you attend one of our events, meet
in person, during phone calls with sales representatives, or when you contact
customer service or support.</span></p>

<p class=MsoNormal align=left style='margin-left:13.5pt;text-align:left;
text-indent:-18.0pt;background:white'><span lang=EN-US style='font-size:10.0pt;
font-family:Symbol;color:#6C7383'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#6C7383'>Information from other sources:</span></b><span lang=EN-US
style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#6C7383'>&nbsp;To
enhance our ability to provide relevant marketing, offers, and services to you,
we may obtain information about you from other sources, such as public
databases, joint marketing partners, social media platforms, as well as from
other third parties.</span></p>
<br>
<p class=MsoNormal align=left style='text-align:left;line-height:28.5pt;
background:white'><span lang=EN-US style='font-size:24.0pt;font-family:"Arial",sans-serif;
color:#454A50'>4. WHY WE PROCESS AND SHARE YOUR PERSONAL DATA</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>We process and share your personal data with other companies for
the purposes listed below:</span></p>
<br>
<p class=MsoNormal align=left style='text-align:left;line-height:24.75pt;
background:white'><span lang=EN-US style='font-size:19.0pt;font-family:"Arial",sans-serif;
color:#454A50'>4.1 Providing you our services</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>Some of the services and features we provide require the
processing of personal data regarding you. We process such personal data in
order to provide our services to you or our other customers, e.g. a business
that you represent.</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>If we have entered into an agreement and the services are
provided to you, our legal basis for such processing of personal data regarding
customers in Europe is Regulation (EU) 2016/679 of the European Parliament and
of the Council of 27 April 2016 on the protection of natural persons with
regard to the processing of personal data and on the free movement of such
data, and repealing Directive 95/46/EC (General Data Protection Regulation)
(“GDPR”) article 6(1)(b), as such processing is required to let you use our
services under the agreements we have entered into.</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>If the services are provided to another customer than you e.g. a
business that you represent or if we have not entered into an agreement, our
legal basis regarding customers in Europe is GDPR article 6(1)(f). Our
legitimate interest pursued by the processing is to be able to provide our
services to our customers.</span></p>
<br>
<p class=MsoNormal align=left style='text-align:left;line-height:24.75pt;
background:white'><span lang=EN-US style='font-size:19.0pt;font-family:"Arial",sans-serif;
color:#454A50'>4.2 Fulfilling our legal obligations</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>Sometimes our legal obligations require that we process personal
data, e.g. when we receive an enforceable governmental request requiring the
processing of personal data which we are under an obligation to comply with.
Our legal basis for such processing of personal data regarding customers in
Europe will be GDPR, article 6(1)(c).</span></p>
<br>
<p class=MsoNormal align=left style='text-align:left;line-height:24.75pt;
background:white'><span lang=EN-US style='font-size:19.0pt;font-family:"Arial",sans-serif;
color:#454A50'>4.3 Customer service and relationship management</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>If you contact us, including our customer service or support,
with an inquiry, e.g. a question or a complaint, we may ask you for or you may
provide us certain information, including personal data. We may use such
personal data to identify you and to help us respond to your inquiry.</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>Our legal basis for such processing of personal data regarding
customers in Europe is GDPR article 6(1)(f). Our legitimate interests pursued
by the processing are to be able to identify you and to respond to your
inquiry.</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>Similarly, we may use contact information provided by you to
contact you, if we have any questions, comments or other information which we
want to address to you.</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>Our legal basis for such processing of personal data regarding
customers in Europe is GDPR article 6(1)(f). Our legitimate interests pursued
by the processing is to be able to identify you and contact you.</span></p>
<br>
<p class=MsoNormal align=left style='text-align:left;line-height:24.75pt;
background:white'><span lang=EN-US style='font-size:19.0pt;font-family:"Arial",sans-serif;
color:#454A50'>4.4 Development and improvement of our products and services</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>We may use personal data which we have collected regarding you
to improve our current products and services or develop new ones, e.g. by using
personal data to assess how our products are currently being used by gathering
statistics regarding the use of certain functionality to better understand
market trends, and thereby improve and develop our websites, product offerings,
and services, or by making use of and evaluating suggestions for improvements
provided by you.</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>We also use personal data to provide you with more user-friendly
services. This relates primarily to your experience with registration and login
procedures. Personal data is also processed with the aim of being able to adapt
the viewing of content on Digital Channels, to the device you use and provide
you with an enhanced user experience.</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>Our legal basis for such processing of personal data regarding
customers in Europe is GDPR article 6(1)(f). Our legitimate interests pursued
by the processing is to be able to improve our current products and services or
to develop new ones.</span></p>

<br>
<p class=MsoNormal align=left style='text-align:left;line-height:24.75pt;
background:white'><span lang=EN-US style='font-size:19.0pt;font-family:"Arial",sans-serif;
color:#454A50'>4.5 Tailored marketing</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>If you have consented to it, we may use personal information
regarding you to provide you with customized offers and advertising, e.g. by
direct email, based on your interests and use of our Digital Channels. For example,
we may assess your personal preferences based on analysing your use and results
of your use of our services, your purchase history, your participation in
promotions or surveys, and other data that you have provided to us.</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>Depending on your consent, you may receive offers by regular
mail, email, SMS, social media, coupons or any other digital channels used by
you. You may use the unsubscribe functionality for the different communication
channels.</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>Our legal basis for providing you with tailored offers will be, if
you are a customer in Europe, GDPR, article 6(1)(a), and the consent you have
given to receiving tailored marketing. If you have consented to receiving
tailored marketing, you may at any time withdraw your consent. Such withdrawal
may be&nbsp;</span><span lang=EN-US style='color:black'><a
href="https://soracom.us16.list-manage.com/unsubscribe?u=f340d3548060a2f5e04db6cc7&amp;id=65740cd5db"><span
style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#006BB7'>performed
here</span></a></span><span lang=EN-US style='font-size:14.5pt;font-family:
"Arial",sans-serif;color:#454A50'>, or you may contact us as set out at
the&nbsp;</span><u><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#006BB7'>bottom of this policy.</span></u><span lang=EN-US
style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#454A50'>&nbsp;Your
withdrawal of consent will not affect the lawfulness of processing based on
your consent before the withdrawal.</span></p>

<br>
<p class=MsoNormal align=left style='text-align:left;line-height:24.75pt;
background:white'><span lang=EN-US style='font-size:19.0pt;font-family:"Arial",sans-serif;
color:#454A50'>4.6 Business Transfers</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>In some cases, we may buy or sell assets or businesses. In these
types of transactions, user information is typically one of the business assets
that is transferred. We may disclose personal information to a third party in
the event of any reorganization, merger, sale, joint venture, assignment,
transfer, or other disposition of all or any portion of our business, assets,
or stock (including in connection with any bankruptcy or similar proceedings).</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>Our legal basis for such processing of personal data regarding
customers in Europe is GDPR article 6(1)(f). Our legitimate interests pursued
by the processing is to be able to buy or sell assets or businesses.</span></p>

<br>
<p class=MsoNormal align=left style='text-align:left;line-height:24.75pt;
background:white'><span lang=EN-US style='font-size:19.0pt;font-family:"Arial",sans-serif;
color:#454A50'>4.7 To prevent, limit, and investigate abuse</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>We need personal data about user activities and technical data
to prevent, limit, and investigate various types of abuse of our Digital
Channels, such as the presentation of false profiles, spamming, harassment,
attempts to log into other users’ accounts, as well as other behaviour which is
prohibited by law.</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>Our legal basis for such processing of personal data regarding
customers in Europe is GDPR article 6(1)(f). Our legitimate interests pursued
by the processing is to be able to prevent, limit, and investigate abuse.</span></p>

<br>>
<p class=MsoNormal align=left style='text-align:left;line-height:28.5pt;
background:white'><span lang=EN-US style='font-size:24.0pt;font-family:"Arial",sans-serif;
color:#454A50'>5. SORACOM AND SOCIAL MEDIA PAGES</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>When you use our Digital Channels, you may share information on
social media, such as Facebook, LinkedIn, Instagram or Twitter, through an
implemented social plug-in (such as a Like button). If you choose to share
information via a social plug-in, your browser will transfer the following data
to the social medium:</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:1.5pt;margin-left:13.5pt;text-align:left;text-indent:-18.0pt;
background:white'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#6C7383'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#6C7383'>Date and time of your visit</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:1.5pt;margin-left:13.5pt;text-align:left;text-indent:-18.0pt;
background:white'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#6C7383'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#6C7383'>The internet address or URL for the address you are temporarily
visiting</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:1.5pt;margin-left:13.5pt;text-align:left;text-indent:-18.0pt;
background:white'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#6C7383'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#6C7383'>Your IP address</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:1.5pt;margin-left:13.5pt;text-align:left;text-indent:-18.0pt;
background:white'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#6C7383'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#6C7383'>The browser you are using</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:1.5pt;margin-left:13.5pt;text-align:left;text-indent:-18.0pt;
background:white'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#6C7383'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#6C7383'>The operating system you are using</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:1.5pt;margin-left:13.5pt;text-align:left;text-indent:-18.0pt;
background:white'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#6C7383'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#6C7383'>Your username and password and, where applicable, whether you
are a registered user of the social medium, your first name and surname</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:1.5pt;margin-left:13.5pt;text-align:left;text-indent:-18.0pt;
background:white'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#6C7383'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#6C7383'>The information for which you have used this specific plug-in</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>With respect to such information, we refer to the terms and
conditions for the relevant social medium (we have no influence over the
information that the social medium collects through the use of plug-ins). You
can block social plug-ins in your browser settings.</span></p>

<br>
<p class=MsoNormal align=left style='text-align:left;line-height:28.5pt;
background:white'><span lang=EN-US style='font-size:24.0pt;font-family:"Arial",sans-serif;
color:#454A50'>6. HOW DOES SORACOM SECURE PERSONAL DATA?</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>We have implemented organizational, technical, and
administrative measures to protect personal information within our
organization, including security controls to prevent unauthorized access to our
systems. While we take reasonable steps to secure your personal information,
you should be aware no security procedures or protocols are ever guaranteed to
be 100 % secure. There is therefore always some risk assumed by sharing
personal information online. If you have reason to believe that your
interaction with us should not be secure, please contact us immediately
(see&nbsp;</span><u><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#006BB7'>How to contact us</span></u><span lang=EN-US style='font-size:
14.5pt;font-family:"Arial",sans-serif;color:#454A50'>&nbsp;below).</span></p>

<br>
<p class=MsoNormal align=left style='text-align:left;line-height:28.5pt;
background:white'><span lang=EN-US style='font-size:24.0pt;font-family:"Arial",sans-serif;
color:#454A50'>7. HOW LONG WILL WE STORE YOUR DATA FOR?</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>We will retain your personal information for the period
necessary to fulfil the purposes outlined in this Privacy Policy. When your
personal data is no longer relevant for the purposes for which it has been
collected, we will delete it.</span></p>

<br>
<p class=MsoNormal align=left style='text-align:left;line-height:28.5pt;
background:white'><span lang=EN-US style='font-size:24.0pt;font-family:"Arial",sans-serif;
color:#454A50'>8. OPTING OUT</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>You may opt-out of receiving marketing communication by
unsubscribing through the unsubscribe or opt-out link in any email. Please note
that if you opt-out of receiving marketing-related emails from us, we may still
send you important administrative messages.</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>You can also close your Soracom account via the link in the
administration console, provided you have no active services and no outstanding
amounts due.</span></p>

<br>
<p class=MsoNormal align=left style='text-align:left;line-height:28.5pt;
background:white'><span lang=EN-US style='font-size:24.0pt;font-family:"Arial",sans-serif;
color:#454A50'>9. HOW CAN YOU ACCESS, CORRECT OR REMOVE DATA?</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>You have the right to request access to, rectification or
erasure of, or restriction of the processing of personal data regarding you.</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>You may also object to any processing of personal data regarding
you.</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>You also have the right to data portability.</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>If you would like to access, correct, remove or limit the use or
disclosure of any personal information about you that has been collected and
stored by Soracom, please contact us as described in ‘How to Contact Us’ below.</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>If you are dissatisfied with our processing of personal data
regarding you, you have the right to lodge a complaint to your local data
protection authority such as the Information Commissioner’s Office (ICO):</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>Information Commissioner's Office</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF <br>
Tel: 0303 123 1113<br>
Fax: 01625 524510</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>Please visit&nbsp;</span><span lang=EN-US style='color:black'><a
href="https://ico.org.uk/make-a-complaint/"><span style='font-size:14.5pt;
font-family:"Arial",sans-serif'>https://ico.org.uk/make-a-complaint/</span></a></span><span
lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#454A50'>
for more information.</span></p>

<br>
<p class=MsoNormal align=left style='text-align:left;line-height:28.5pt;
background:white'><span lang=EN-US style='font-size:24.0pt;font-family:"Arial",sans-serif;
color:#454A50'>10. THIRD PARTY SITES</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>Our Digital Channels may permit you to link to other websites on
the Internet through direct links or through applications such as “share” or
“Like” buttons, and other websites likewise may contain links to our Digital
Channels. The information practices or content of such other websites is not
governed by this Privacy Policy, but the privacy statements of those websites.</span></p>

<br>
<p class=MsoNormal align=left style='text-align:left;line-height:28.5pt;
background:white'><span lang=EN-US style='font-size:24.0pt;font-family:"Arial",sans-serif;
color:#454A50'>11. CHANGES TO THIS POLICY</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>Soracom may update this Privacy Policy from time to time, for
example, to reflect changes to data protection law.</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>The “Latest updated Date” on this page indicates when this
Privacy Policy was last revised. Any changes to this Privacy Policy will become
effective when we publish the revised Privacy Policy. Large changes in our
Privacy Policy will be notified 30 days before they become effective.</span></p>

<br>
<p class=MsoNormal align=left style='text-align:left;line-height:28.5pt;
background:white'><span lang=EN-US style='font-size:24.0pt;font-family:"Arial",sans-serif;
color:#454A50'>12. TRANSFER OF PERSONAL DATA REGARDING YOU</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>We may disclose personal data regarding you to any of the
following categories of recipients:</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:1.5pt;margin-left:13.5pt;text-align:left;text-indent:-18.0pt;
background:white'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#6C7383'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#6C7383'>Our data processors, e.g. data processors used for the operation
of our websites or hosting our data;</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:1.5pt;margin-left:13.5pt;text-align:left;text-indent:-18.0pt;
background:white'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#6C7383'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#6C7383'>Subject to applicable data protection legislation, any affiliates,
agents, service providers and/or subcontractors of us for the purposes listed
in this Privacy Policy. e.g. authorised third party companies involved in the
processing of payments made on our website, or other third parties helping us
achieve the purposes listed in this privacy policy. Our legal basis for such
disclosure of personal data </span><span lang=EN-US style='font-size:14.5pt;
font-family:"Arial",sans-serif;color:#454A50'>regarding customers in Europe</span><span
lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#6C7383'>
may be GDPR, article 6(1)(f). Our legitimate interests pursued by the
processing is to be able to provide you with our services including in a
cost-effective manner;</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:1.5pt;margin-left:13.5pt;text-align:left;text-indent:-18.0pt;
background:white'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#6C7383'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#6C7383'>Authorised governmental entities, supervisory agencies, fiscal
entities etc. pursuant to a legal process or enforceable governmental request
which we are under an obligation to comply with. Our legal basis for such
disclosure of personal data </span><span lang=EN-US style='font-size:14.5pt;
font-family:"Arial",sans-serif;color:#454A50'>regarding customers in Europe</span><span
lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#6C7383'>
will be GDPR, article 6(1)(c).</span></p>

<br>
<p class=MsoNormal align=left style='text-align:left;line-height:28.5pt;
background:white'><span lang=EN-US style='font-size:24.0pt;font-family:"Arial",sans-serif;
color:#454A50'>13. Transfer of data to a non-EU/EEA country</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>We transfer your personal data to the following affiliated
companies which are headquartered outside of the EU/EEA. We have entered into
agreements with each of the below, which include data protection Model Clauses
to contractually protect your data and privacy. Please Contact us if you have
any questions.</span></p>

<br>
<p class=MsoNormal align=left style='text-align:left;line-height:24.75pt;
background:white'><span lang=EN-US style='font-size:19.0pt;font-family:"Arial",sans-serif;
color:#454A50'>13.1 SORACOM INTERNATIONAL, PTE. LTD, SORACOM GLOBAL, INC</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>If you have signed up for Automated Credit Card Payment, Soracom
may disclose and transfer your Credit Card information to Soracom’s affiliated
company, SORACOM INTERNATIONAL, PTE. LTD. (address: 80 Robinson Road #10-01A
Singapore 068898) and Soracom Global, Inc. (address: 10400 NE 4th St, 5th Floor,
Bellevue, WA 98004, USA) for the purposes of processing your payment.</span></p>

<br>
<p class=MsoNormal align=left style='text-align:left;line-height:24.75pt;
background:white'><span lang=EN-US style='font-size:19.0pt;font-family:"Arial",sans-serif;
color:#454A50'>13.2 SORACOM INC.</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>Soracom Inc. (4-5-6-3F Tamagawa Setagaya Tokyo, Japan 158-0094)
is our affiliated company who operate and develop the Soracom websites and services.
Soracom CORPORATION, LTD. resells these services as a reseller. When you sign
up for a Soracom Account, the Soracom Partner Space, or browse our other
websites, the data is transferred to Soracom Inc, because they operate the
underlying systems and services. Soracom Inc. is ISO27001 certified and we have
model clauses to help protect your personal information and privacy.</span></p>

<br>
<p class=MsoNormal align=left style='text-align:left;line-height:28.5pt;
background:white'><span lang=EN-US style='font-size:24.0pt;font-family:"Arial",sans-serif;
color:#454A50'>14. WHEN THIS POLICY DOES NOT APPLY</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>This Privacy Policy does not apply to the practices of third
parties that Soracom does not own or control, or to individuals that Soracom
does not employ or manage. This Privacy Policy also does not apply to
information that we receive or handle on behalf of our customers in our role as
a service provider or partner. Such information is governed by the terms of the
applicable services agreement, rather than this Privacy Policy.</span></p>

<br>
<p class=MsoNormal align=left style='text-align:left;line-height:28.5pt;
background:white'><span lang=EN-US style='font-size:24.0pt;font-family:"Arial",sans-serif;
color:#454A50'>15. CONSEQUENCES OF NOT PROVIDING PERSONAL DATA</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>If you do not provide us with your personal data, you will not
be able to open a Soracom account or use Soracom’s services.</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>If you do not provide a shipping address, we may be unable
fulfil your order.</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>If you do not provide a payment information, your use of the
Soracom services will be limited.</span></p>

<br>
<p class=MsoNormal align=left style='text-align:left;line-height:28.5pt;
background:white'><span lang=EN-US style='font-size:24.0pt;font-family:"Arial",sans-serif;
color:#454A50'>16. HOW TO CONTACT US</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>Your privacy matters. If you have any questions, concerns, or
complaints regarding the way we collect and handle your information, please
visit&nbsp;</span><span lang=EN-US style='color:black'><a
href="https://www.soracom.io/contact/"><span style='font-size:14.5pt;
font-family:"Arial",sans-serif;color:#006BB7'>https://www.soracom.io/contact</span></a></span><span
lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#454A50'>.</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>If you have a Soracom Account, please login and then click
‘Support’ at the top right and raise a request in our support system. This is
described at the bottom of&nbsp;</span><span lang=EN-US style='color:black'><a
href="https://www.soracom.io/contact/"><span style='font-size:14.5pt;
font-family:"Arial",sans-serif;color:#006BB7'>https://www.soracom.io/contact</span></a></span><span
lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#454A50'>.</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>If you have a Soracom Mobile Account, please visit </span><span
lang=EN-US style='color:black'><a href="https://soracommobile.com/contact/"><span
style='font-size:14.5pt;font-family:"Arial",sans-serif'>https://soracommobile.com/contact/</span></a></span><span
lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;color:#454A50'> 
or email to support@soracommobile.com</span></p>

<p class=MsoNormal align=left style='text-align:left;line-height:21.0pt;
background:white'><span lang=EN-US style='font-size:14.5pt;font-family:"Arial",sans-serif;
color:#454A50'>Soracom will take any privacy complaint seriously and any
complaint will be assessed by an appropriate person with the aim of resolving
any issue in a timely and efficient manner.</span></p>

<p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>

</div>`
