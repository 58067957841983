module.exports = `<p class=MsoNormal align=left style='margin-top:24.0pt;margin-right:0cm;
margin-bottom:12.0pt;margin-left:0cm;text-align:left'><span lang=EN-US
style='font-size:18.0pt;font-family:Meiryo;color:#333333'>Soracom Mobile</span><span
lang=JA style='font-size:18.0pt;font-family:Meiryo;color:#333333'>契約者に関する個人情報の取り扱いに関する方針</span></p>

<p class=MsoNormal style='margin-bottom:20.55pt;line-height:20.55pt'><span
lang=EN-US style='font-family:Meiryo;color:#333333'>16 Great Queen Street,
Covent Garden, London, United Kingdom, WC2B 5AH</span><span lang=JA
style='font-family:Meiryo;color:#333333'>に所在する英国法人である</span><span lang=EN-US
style='font-family:Meiryo;color:#333333'>SORACOM CORPORATION, LTD. (</span><span
lang=JA style='font-family:Meiryo;color:#333333'>以下、「当社」といいます。</span><span
lang=EN-US style='font-family:Meiryo;color:#333333'>)</span><span lang=JA
style='font-family:Meiryo;color:#333333'>は、個人情報の保護に関する法律その他関連する法令等を遵守するとともに、個人情報を、以下により取り扱うこととします。</span></p>

<p class=MsoNormal style='margin-bottom:20.55pt;line-height:20.55pt'><b><span
lang=EN-US style='font-family:Meiryo;color:#333333'>1. </span></b><b><span
lang=JA style='font-family:Meiryo;color:#333333'>個人情報の利用目的</span></b><span
lang=EN-US style='font-family:Meiryo;color:#333333'><br>
(1)</span><span lang=JA style='font-family:Meiryo;color:#333333'>当社は、電気通信サービス</span><span
lang=EN-US style='font-family:Meiryo;color:#333333'>(</span><span lang=JA
style='font-family:Meiryo;color:#333333'>通信機器本体の機能を利用して提供される各種サービス</span><span
lang=EN-US style='font-family:Meiryo;color:#333333'>)</span><span lang=JA
style='font-family:Meiryo;color:#333333'>の提供等にあたり、次の利用目的の達成に必要な範囲内で下記</span><span
lang=EN-US style='font-family:Meiryo;color:#333333'>(2)</span><span lang=JA
style='font-family:Meiryo;color:#333333'>に記載の個人情報を利用します。</span></p>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0 width=614
 style='width:460.3pt;border-collapse:collapse;border:none'>
 <tr>
  <td width=396 style='width:297.3pt;border:solid #DDDDDD 1.0pt;background:
  #F8F8F8;padding:12.0pt 12.0pt 12.0pt 12.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:20.55pt'><span
  lang=JA style='font-family:Meiryo;color:#333333'>個人情報の利用目的</span></p>
  </td>
  <td width=217 style='width:163.0pt;border:solid #DDDDDD 1.0pt;border-left:
  none;background:#F8F8F8;padding:12.0pt 12.0pt 12.0pt 12.0pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:20.55pt'><span
  lang=JA style='font-family:Meiryo;color:#333333'>利用する個人情報</span></p>
  </td>
 </tr>
 <tr>
  <td width=396 style='width:297.3pt;border:none;border-left:solid #DDDDDD 1.0pt;
  padding:12.0pt 12.0pt 12.0pt 12.0pt'>
  <p class=MsoNormal align=left style='text-align:left;line-height:20.55pt'><span
  lang=JA style='font-family:Meiryo;color:#333333'>①電気通信サービスの提供・保守、商品・サービス・ネットワーク等の設備・機器・ソフトウェアの障害・不具合・事故発生時の調査・対応等アフターサービス、ご利用料金等の計算・請求その他関連する業務のため</span></p>
  </td>
  <td width=217 style='width:163.0pt;border-top:none;border-left:solid #DDDDDD 1.0pt;
  border-bottom:none;border-right:solid #DDDDDD 1.0pt;padding:12.0pt 12.0pt 12.0pt 12.0pt'>
  <p class=MsoNormal align=left style='text-align:left;line-height:20.55pt'><span
  lang=EN-US style='font-family:Meiryo;color:#333333'>(2)</span><span lang=JA
  style='font-family:Meiryo;color:#333333'>①〜③、⑤〜⑩</span></p>
  </td>
 </tr>
 <tr>
  <td width=396 style='width:297.3pt;border-top:solid #DDDDDD 1.0pt;border-left:
  solid #DDDDDD 1.0pt;border-bottom:none;border-right:none;padding:12.0pt 12.0pt 12.0pt 12.0pt'>
  <p class=MsoNormal align=left style='text-align:left;line-height:20.55pt'><span
  lang=JA style='font-family:Meiryo;color:#333333'>②お申込み受付時等の本人確認・契約審査その他関連する業務のため</span></p>
  </td>
  <td width=217 style='width:163.0pt;border:solid #DDDDDD 1.0pt;border-bottom:
  none;padding:12.0pt 12.0pt 12.0pt 12.0pt'>
  <p class=MsoNormal align=left style='text-align:left;line-height:20.55pt'><span
  lang=EN-US style='font-family:Meiryo;color:#333333'>(2)</span><span lang=JA
  style='font-family:Meiryo;color:#333333'>①〜⑥、⑩</span></p>
  </td>
 </tr>
 <tr>
  <td width=396 style='width:297.3pt;border-top:solid #DDDDDD 1.0pt;border-left:
  solid #DDDDDD 1.0pt;border-bottom:none;border-right:none;padding:12.0pt 12.0pt 12.0pt 12.0pt'>
  <p class=MsoNormal align=left style='text-align:left;line-height:20.55pt'><span
  lang=JA style='font-family:Meiryo;color:#333333'>③お客様からの相談</span><span
  lang=EN-US style='font-family:Meiryo;color:#333333'>(</span><span lang=JA
  style='font-family:Meiryo;color:#333333'>ご意見・ご要望・お問い合わせ</span><span
  lang=EN-US style='font-family:Meiryo;color:#333333'>)</span><span lang=JA
  style='font-family:Meiryo;color:#333333'>等への対応のため</span></p>
  </td>
  <td width=217 style='width:163.0pt;border:solid #DDDDDD 1.0pt;border-bottom:
  none;padding:12.0pt 12.0pt 12.0pt 12.0pt'>
  <p class=MsoNormal align=left style='text-align:left;line-height:20.55pt'><span
  lang=EN-US style='font-family:Meiryo;color:#333333'>(2)</span><span lang=JA
  style='font-family:Meiryo;color:#333333'>①〜⑩</span></p>
  </td>
 </tr>
 <tr>
  <td width=396 style='width:297.3pt;border-top:solid #DDDDDD 1.0pt;border-left:
  solid #DDDDDD 1.0pt;border-bottom:none;border-right:none;padding:12.0pt 12.0pt 12.0pt 12.0pt'>
  <p class=MsoNormal align=left style='text-align:left;line-height:20.55pt'><span
  lang=JA style='font-family:Meiryo;color:#333333'>④販売状況・利用状況の分析、各種施策実施のための分析および当該施策の効果測定、新サービス企画のための分析、サービス品質改善・応対サービス向上のための分析その他各種分析・調査の実施のため</span></p>
  </td>
  <td width=217 style='width:163.0pt;border:solid #DDDDDD 1.0pt;border-bottom:
  none;padding:12.0pt 12.0pt 12.0pt 12.0pt'>
  <p class=MsoNormal align=left style='text-align:left;line-height:20.55pt'><span
  lang=EN-US style='font-family:Meiryo;color:#333333'>(2)</span><span lang=JA
  style='font-family:Meiryo;color:#333333'>①、②、⑤〜⑩</span></p>
  </td>
 </tr>
 <tr>
  <td width=396 style='width:297.3pt;border-top:solid #DDDDDD 1.0pt;border-left:
  solid #DDDDDD 1.0pt;border-bottom:none;border-right:none;padding:12.0pt 12.0pt 12.0pt 12.0pt'>
  <p class=MsoNormal align=left style='text-align:left;line-height:20.55pt'><span
  lang=JA style='font-family:Meiryo;color:#333333'>⑤不正契約・不正利用・不払いの発生防止および発生時の調査・対応のため</span></p>
  </td>
  <td width=217 style='width:163.0pt;border:solid #DDDDDD 1.0pt;border-bottom:
  none;padding:12.0pt 12.0pt 12.0pt 12.0pt'>
  <p class=MsoNormal align=left style='text-align:left;line-height:20.55pt'><span
  lang=EN-US style='font-family:Meiryo;color:#333333'>(2)</span><span lang=JA
  style='font-family:Meiryo;color:#333333'>①〜⑥、⑩</span></p>
  </td>
 </tr>
 <tr>
  <td width=396 style='width:297.3pt;border-top:solid #DDDDDD 1.0pt;border-left:
  solid #DDDDDD 1.0pt;border-bottom:none;border-right:none;padding:12.0pt 12.0pt 12.0pt 12.0pt'>
  <p class=MsoNormal align=left style='text-align:left;line-height:20.55pt'><span
  lang=JA style='font-family:Meiryo;color:#333333'>⑥商品・サービス・キャンペーン・イベント等のご案内、広告の表示・配信、ご注文いただいた商品等の発送、キャンペーン・イベント等における当選者の抽選および景品の発送その他お知らせの実施のため</span></p>
  </td>
  <td width=217 style='width:163.0pt;border:solid #DDDDDD 1.0pt;border-bottom:
  none;padding:12.0pt 12.0pt 12.0pt 12.0pt'>
  <p class=MsoNormal align=left style='text-align:left;line-height:20.55pt'><span
  lang=EN-US style='font-family:Meiryo;color:#333333'>(2)</span><span lang=JA
  style='font-family:Meiryo;color:#333333'>①、②、⑤〜⑦、⑩</span></p>
  </td>
 </tr>
 <tr>
  <td width=396 style='width:297.3pt;border-top:solid #DDDDDD 1.0pt;border-left:
  solid #DDDDDD 1.0pt;border-bottom:none;border-right:none;padding:12.0pt 12.0pt 12.0pt 12.0pt'>
  <p class=MsoNormal align=left style='text-align:left;line-height:20.55pt'><span
  lang=JA style='font-family:Meiryo;color:#333333'>⑦アンケート調査に関する業務のため</span></p>
  </td>
  <td width=217 style='width:163.0pt;border:solid #DDDDDD 1.0pt;border-bottom:
  none;padding:12.0pt 12.0pt 12.0pt 12.0pt'>
  <p class=MsoNormal align=left style='text-align:left;line-height:20.55pt'><span
  lang=EN-US style='font-family:Meiryo;color:#333333'>(2)</span><span lang=JA
  style='font-family:Meiryo;color:#333333'>①〜③、⑤〜⑩</span></p>
  </td>
 </tr>
 <tr>
  <td width=396 style='width:297.3pt;border:solid #DDDDDD 1.0pt;border-right:
  none;padding:12.0pt 12.0pt 12.0pt 12.0pt'>
  <p class=MsoNormal align=left style='text-align:left;line-height:20.55pt'><span
  lang=EN-US style='font-family:Meiryo;color:#333333'>&nbsp;</span></p>
  </td>
  <td width=217 style='width:163.0pt;border:solid #DDDDDD 1.0pt;padding:12.0pt 12.0pt 12.0pt 12.0pt'>
  <p class=MsoNormal align=left style='text-align:left;line-height:20.55pt'><span
  lang=EN-US style='font-family:Meiryo;color:#333333'>&nbsp;</span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='margin-bottom:20.55pt;line-height:20.55pt'><span
lang=JA style='font-family:Meiryo;color:#333333'>なお、当社はお客様との電気通信サービスに関する契約が終了した後においても、上記の利用目的の範囲内で個人情報を利用することがあります。</span></p>

<p class=MsoNormal style='margin-bottom:20.55pt;line-height:20.55pt'><span
lang=EN-US style='font-family:Meiryo;color:#333333'>(2)</span><span lang=JA
style='font-family:Meiryo;color:#333333'>当社は、次の個人情報を上記</span><span lang=EN-US
style='font-family:Meiryo;color:#333333'>(1)</span><span lang=JA
style='font-family:Meiryo;color:#333333'>に記載の利用目的の達成に必要な範囲内で利用します。</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>①氏名・メールアドレス・</span><span
lang=EN-US style='font-family:Meiryo;color:#333333'>Apple ID</span><span
lang=JA style='font-family:Meiryo;color:#333333'>等の情報</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:20.55pt;
margin-left:30.85pt;line-height:20.55pt'><span lang=JA style='font-family:Meiryo;
color:#333333'>氏名・住所・生年月日・電話番号・メールアドレス等</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>②ご利用料金等に関する情報</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:20.55pt;
margin-left:30.85pt;line-height:20.55pt'><span lang=JA style='font-family:Meiryo;
color:#333333'>ご請求金額・お支払状況・お支払方法等のご利用料金に関する情報</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>③お支払方法等の情報</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:20.55pt;
margin-left:30.85pt;line-height:20.55pt'><span lang=JA style='font-family:Meiryo;
color:#333333'>クレジットカード番号・名義等</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>④公的証明書等に記載された情報</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:20.55pt;
margin-left:30.85pt;line-height:20.55pt'><span lang=JA style='font-family:Meiryo;
color:#333333'>登記簿謄本、印鑑登録証明書、運転免許証、パスポート等の本人確認書類に記載された情報</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>⑤お申込みいただいたサービス内容に関する情報</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:20.55pt;
margin-left:30.85pt;line-height:20.55pt'><span lang=JA style='font-family:Meiryo;
color:#333333'>電気通信サービスに関する情報</span><span lang=EN-US style='font-family:Meiryo;
color:#333333'><br>
</span><span lang=JA style='font-family:Meiryo;color:#333333'>付加サービスに関する情報</span><span
lang=EN-US style='font-family:Meiryo;color:#333333'><br>
</span><span lang=JA style='font-family:Meiryo;color:#333333'>通信機器本体の機能を利用して提供される各種サービスに関する情報</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>⑥ご利用の通信機器本体に関する情報</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:20.55pt;
margin-left:30.85pt;line-height:20.55pt'><span lang=JA style='font-family:Meiryo;
color:#333333'>機種名、製造番号等のご利用の通信機器本体に関する情報</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>⑦通信履歴に関する情報</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:20.55pt;
margin-left:30.85pt;line-height:20.55pt'><span lang=JA style='font-family:Meiryo;
color:#333333'>通信開始</span><span lang=EN-US style='font-family:Meiryo;
color:#333333'>/</span><span lang=JA style='font-family:Meiryo;color:#333333'>終了時刻・通信時間・通信先番号等の通信履歴に関する情報</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>⑧位置情報</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:20.55pt;
margin-left:30.85pt;line-height:20.55pt'><span lang=JA style='font-family:Meiryo;
color:#333333'>位置情報に関する情報</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>⑨閲覧・利用に関する情報</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:20.55pt;
margin-left:30.85pt;line-height:20.55pt'><span lang=JA style='font-family:Meiryo;
color:#333333'>スマートフォン用アプリケーション、</span><span lang=EN-US style='font-family:
Meiryo;color:#333333'>Web</span><span lang=JA style='font-family:Meiryo;
color:#333333'>ページ等の閲覧・利用・接続に関する情報</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>⑩その他申込み受付履歴、ご意見・ご要望・お問い合わせ等の内容等、電気通信サービスの提供等に付随して取得した情報</span></p>

<p class=MsoNormal style='margin-bottom:20.55pt;line-height:20.55pt'><span
lang=EN-US style='font-family:Meiryo;color:#333333'> (</span><span lang=JA
style='font-family:Meiryo;color:#333333'>３</span><span lang=EN-US
style='font-family:Meiryo;color:#333333'>)</span><span lang=JA
style='font-family:Meiryo;color:#333333'>上記のほか、当社は、当社が保有する個人情報を、個人を識別できない形に加工した上で、第三者への提供を行うことがあります。</span></p>

<p class=MsoNormal style='margin-bottom:20.55pt;line-height:20.55pt'><span
lang=EN-US style='font-family:Meiryo;color:#333333'>(</span><span lang=JA
style='font-family:Meiryo;color:#333333'>４</span><span lang=EN-US
style='font-family:Meiryo;color:#333333'>)</span><span lang=JA
style='font-family:Meiryo;color:#333333'>上記</span><span lang=EN-US
style='font-family:Meiryo;color:#333333'>(1)</span><span lang=JA
style='font-family:Meiryo;color:#333333'>～</span><span lang=EN-US
style='font-family:Meiryo;color:#333333'>(</span><span lang=JA
style='font-family:Meiryo;color:#333333'>３</span><span lang=EN-US
style='font-family:Meiryo;color:#333333'>)</span><span lang=JA
style='font-family:Meiryo;color:#333333'>の規定に関わらず、次の各号のいずれかに該当すると認める場合は、本人の権利利益に最大限の配慮を払いつつ、利用目的以外の目的のために個人情報を利用し、または個人情報を第三者に提供することがあります。</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>①本人の同意を得た場合。</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>②法令に基づく場合。</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>③生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合。</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>④公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難である場合。</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>⑤国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合。</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>⑥利用目的の達成に必要な範囲で、個人情報の取り扱いを委託する場合</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>⑦合併、会社分割、営業譲渡その他の事由によって事業の承継が行われる場合</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;line-height:17.15pt'><span
lang=EN-US style='font-family:Meiryo;color:#333333'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:20.55pt;line-height:20.55pt'><b><span
lang=EN-US style='font-family:Meiryo;color:#333333'>2. </span></b><b><span
lang=JA style='font-family:Meiryo;color:#333333'>個人情報の管理</span></b><span
lang=EN-US style='font-family:Meiryo;color:#333333'><br>
</span><span lang=JA style='font-family:Meiryo;color:#333333'>当社は、個人情報への不正なアクセスの防止、個人情報の漏えい・滅失・き損の防止その他の個人情報の安全管理のために必要かつ適切な措置を講じます。</span><span
lang=EN-US style='font-family:Meiryo;color:#333333'><br>
</span><span lang=JA style='font-family:Meiryo;color:#333333'>当社は、個人情報の適正な取り扱いの確保のため、従業者に対し、必要な教育研修を実施します。</span><span
lang=EN-US style='font-family:Meiryo;color:#333333'><br>
</span><span lang=JA style='font-family:Meiryo;color:#333333'>当社は、個人情報の取り扱い業務の全部または一部を委託する場合があります。この場合、当社は、個人情報を適正に取り扱うと認められるものを選定し、委託契約において、安全管理措置、秘密保持、再委託の条件その他の個人情報の取り扱いに関する事項について適正に定め、必要かつ適切な監督を実施します。</span></p>

<p class=MsoNormal style='margin-bottom:20.55pt;line-height:20.55pt'><b><span
lang=EN-US style='font-family:Meiryo;color:#333333'>3. </span></b><b><span
lang=JA style='font-family:Meiryo;color:#333333'>個人情報の開示</span></b><span
lang=EN-US style='font-family:Meiryo;color:#333333'><br>
</span><span lang=JA style='font-family:Meiryo;color:#333333'>当社は、お客様本人から、自己に関する個人情報の開示の求めがあった場合には遅滞なく回答します。開示の手続きは次の各号の通りとなります。</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>① 申請方法</span><span
lang=EN-US style='font-family:Meiryo;color:#333333'>(</span><span lang=JA
style='font-family:Meiryo;color:#333333'>郵送での申請となります</span><span lang=EN-US
style='font-family:Meiryo;color:#333333'>)</span></p>

<p class=MsoNormal style='margin-left:30.85pt;line-height:20.55pt'><span
lang=JA style='font-family:Meiryo;color:#333333'>「個人情報開示申請書」に所定事項をすべて記載のうえ、本人確認書類を同封し、下記送付先まで郵送ください。なお、当社への郵送料はお客様にてご負担ください。申請書はスマートフォン用アプリケーションもしくは</span><span
lang=EN-US style='font-family:Meiryo;color:#333333'>Web</span><span lang=JA
style='font-family:Meiryo;color:#333333'>サイト内の問い合わせ</span><span lang=EN-US
style='font-family:Meiryo;color:#333333'>(</span><span lang=JA
style='font-family:Meiryo;color:#333333'>サポート</span><span lang=EN-US
style='font-family:Meiryo;color:#333333'>)</span><span lang=JA
style='font-family:Meiryo;color:#333333'>機能又は電子メール（</span><span lang=EN-US
style='font-family:Meiryo;color:#333333'>support@soracommobile.com</span><span
lang=JA style='font-family:Meiryo;color:#333333'>）を利用し、ご請求ください。必要な本人確認書類と合わせて、ご案内させていただきます。</span><span
lang=EN-US style='font-family:Meiryo;color:#333333'><br>
</span><span lang=JA style='font-family:Meiryo;color:#333333'>【送付先】</span><span
lang=EN-US style='font-family:Meiryo;color:#333333'><br>
SORACOM CORPORATION, LTD.</span></p>

<p class=MsoNormal style='margin-left:30.85pt;line-height:20.55pt'><span
lang=EN-US style='font-family:Meiryo;color:#333333'>16 Great Queen Street,
Covent Garden, London, United Kingdom, WC2B 5AH</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>② 本人確認方法</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:20.55pt;
margin-left:30.85pt;line-height:20.55pt'><span lang=JA style='font-family:Meiryo;
color:#333333'>申請にあたっては、当社の定める本人確認書類を同封願います。</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>③ 回答方法</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:20.55pt;
margin-left:30.85pt;line-height:20.55pt'><span lang=JA style='font-family:Meiryo;
color:#333333'>原則、お客様ご本人の住所宛に書面によって回答いたします。</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>④ 開示に関する手数料</span></p>

<p class=MsoNormal align=left style='margin-left:30.85pt;text-align:left;
line-height:17.15pt'><span lang=EN-US style='font-family:Meiryo;color:#333333'>1</span><span
lang=JA style='font-family:Meiryo;color:#333333'>回の開示申請ごとに、</span><span
lang=EN-US style='font-family:Meiryo;color:#333333'>2,000</span><span lang=JA
style='font-family:Meiryo;color:#333333'>円（税込）の開示手数料が必要です。「個人情報開示申請書」等の必要書類と合わせて、「定額小為替証書」</span><span
lang=EN-US style='font-family:Meiryo;color:#333333'>2,000</span><span lang=JA
style='font-family:Meiryo;color:#333333'>円分を同封願います。</span></p>

<p class=MsoNormal align=left style='margin-left:30.85pt;text-align:left;
line-height:17.15pt'><span lang=JA style='font-family:Meiryo;color:#333333'>※手数料が不足していた場合、及び手数料が同封されていなかった場合は、その旨ご連絡いたしますが、所定の期間内にお支払いがない場合は、開示、利用目的の通知のご請求がなかったものとして対応させていただきます。</span></p>

<p class=MsoNormal style='margin-bottom:20.55pt;line-height:20.55pt'><span
lang=JA style='font-family:Meiryo;color:#333333'>なお、当社は次の各号のいずれかに該当する場合は、当該申請に係る個人情報の全部または一部について開示を行わないことがあります。その場合は、理由を付記してお知らせいたします。その全部または一部開示を行わない場合でも、所定の手数料をいただきます。</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>申請書記載の住所、本人確認書類記載の住所、当社の登録住所が一致しない場合等、本人確認ができない場合。</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>所定の申請書類に不備があった場合。</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>開示のご請求の対象が個人情報の保護に関する法律第</span><span
lang=EN-US style='font-family:Meiryo;color:#333333'>2</span><span lang=JA
style='font-family:Meiryo;color:#333333'>条第</span><span lang=EN-US
style='font-family:Meiryo;color:#333333'>7</span><span lang=JA
style='font-family:Meiryo;color:#333333'>項にいう保有個人データに該当しない場合</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>開示の求めの対象が当社に存在しない場合。</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合。</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合。</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:30.85pt;text-align:left;text-indent:-18.0pt;
line-height:17.15pt'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=JA style='font-family:Meiryo;color:#333333'>他の法令等に違反することとなる場合。</span></p>

<p class=MsoNormal align=left style='margin-top:3.4pt;margin-right:0cm;
margin-bottom:3.4pt;margin-left:12.85pt;text-align:left;line-height:17.15pt'><span
lang=EN-US style='font-family:Meiryo;color:#333333'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:20.55pt;line-height:20.55pt'><b><span
lang=EN-US style='font-family:Meiryo;color:#333333'>4. </span></b><b><span
lang=JA style='font-family:Meiryo;color:#333333'>個人情報の訂正等</span></b><span
lang=EN-US style='font-family:Meiryo;color:#333333'><br>
</span><span lang=JA style='font-family:Meiryo;color:#333333'>当社は、お客様本人から自己に関する個人情報の訂正、追加、削除、利用停止または第三者提供の停止</span><span
lang=EN-US style='font-family:Meiryo;color:#333333'>(</span><span lang=JA
style='font-family:Meiryo;color:#333333'>以下、｢訂正等｣と総称します。</span><span
lang=EN-US style='font-family:Meiryo;color:#333333'>)</span><span lang=JA
style='font-family:Meiryo;color:#333333'>の求めがあった場合には、遅滞なく調査を行います。その結果、当該個人情報に関し、内容が事実でない、保存期間を経過している、その他取り扱いが適正でないと認められるときは、遅滞なく訂正等を行います。個人情報の訂正等のお申し出は、スマートフォン用アプリケーションもしくは</span><span
lang=EN-US style='font-family:Meiryo;color:#333333'>Web</span><span lang=JA
style='font-family:Meiryo;color:#333333'>サイト内の問い合わせ</span><span lang=EN-US
style='font-family:Meiryo;color:#333333'>(</span><span lang=JA
style='font-family:Meiryo;color:#333333'>サポート</span><span lang=EN-US
style='font-family:Meiryo;color:#333333'>)</span><span lang=JA
style='font-family:Meiryo;color:#333333'>機能又は電子メール（</span><span lang=EN-US
style='font-family:Meiryo;color:#333333'>support@soracommobile.com</span><span
lang=JA style='font-family:Meiryo;color:#333333'>）を利用し、ご申告願います。</span></p>

<p class=MsoNormal style='margin-bottom:20.55pt;line-height:20.55pt'><b><span
lang=EN-US style='font-family:Meiryo;color:#333333'>5. </span></b><b><span
lang=JA style='font-family:Meiryo;color:#333333'>利用目的の通知</span></b><span
lang=EN-US style='font-family:Meiryo;color:#333333'><br>
</span><span lang=JA style='font-family:Meiryo;color:#333333'>当社は、個人情報の利用目的の通知を求められた場合は、スマートフォン用アプリケーションもしくは</span><span
lang=EN-US style='font-family:Meiryo;color:#333333'>Web</span><span lang=JA
style='font-family:Meiryo;color:#333333'>サイト内の問い合わせ</span><span lang=EN-US
style='font-family:Meiryo;color:#333333'>(</span><span lang=JA
style='font-family:Meiryo;color:#333333'>サポート</span><span lang=EN-US
style='font-family:Meiryo;color:#333333'>)</span><span lang=JA
style='font-family:Meiryo;color:#333333'>機能又は電子メール（</span><span lang=EN-US
style='font-family:Meiryo;color:#333333'>support@soracommobile.com</span><span
lang=JA style='font-family:Meiryo;color:#333333'>）を利用し、対応いたします。</span></p>

<p class=MsoNormal style='margin-bottom:20.55pt;line-height:20.55pt'><b><span
lang=EN-US style='font-family:Meiryo;color:#333333'>6. </span></b><b><span
lang=JA style='font-family:Meiryo;color:#333333'>個人情報の取り扱いに関する相談窓口</span></b><span
lang=EN-US style='font-family:Meiryo;color:#333333'><br>
</span><span lang=JA style='font-family:Meiryo;color:#333333'>当社の個人情報の取り扱いにつきまして、ご意見・ご要望がございましたら、スマートフォン用アプリケーションもしくは</span><span
lang=EN-US style='font-family:Meiryo;color:#333333'>Web</span><span lang=JA
style='font-family:Meiryo;color:#333333'>サイト内の問い合わせ</span><span lang=EN-US
style='font-family:Meiryo;color:#333333'>(</span><span lang=JA
style='font-family:Meiryo;color:#333333'>サポート</span><span lang=EN-US
style='font-family:Meiryo;color:#333333'>)</span><span lang=JA
style='font-family:Meiryo;color:#333333'>機能又はは電子メール（</span><span lang=EN-US
style='font-family:Meiryo;color:#333333'>support@soracommobile.com</span><span
lang=JA style='font-family:Meiryo;color:#333333'>）を利用し、ご連絡くださいますようお願い申し上げます。</span><span
lang=EN-US style='font-family:Meiryo;color:#333333'><br>
</span><span lang=JA style='font-family:Meiryo;color:#333333'>直接のご来社によるお申し出は受けかねますので、ご了承ください。</span></p>

<p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>

</div>`
