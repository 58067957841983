<template>
    <div>
        <div class="container">
            <h1 class="page-title">{{ $t("title") }}</h1>
        </div>

        <section class="section">
            <div class="container" v-html="$t('terms')"></div>
        </section>
    </div>
</template>

<script>
import Question from "@/components/Question"
import FaqStructuredData from "@/components/FaqStructuredData"
export default {
    components: { Question, FaqStructuredData },
    meta() {
        return this.$generateMeta({
            title: this.$i18n.t("meta.title"),
            description: this.$i18n.t("meta.description"),
        })
    },

    i18n: {
        messages: {
            en: {
                meta: {
                    title: "Privacy Policy",
                    description: "Privacy Policy",
                },
                title: "Privacy Policy",

                terms: require("@/data/privacy/en"),
            },
            ja: {
                terms: require("@/data/privacy/ja"),
                meta: {
                    title: "個人情報保護方針",
                    description: "ソラコム モバイルの個人情報保護方針です",
                },
                title: "個人情報保護方針",
            },
        },
    },
}
</script>
